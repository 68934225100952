import img1 from './images/a-man-called-ove-by-fredrick-backman.jpg';
import img2 from './images/the-silent-patient-by-alex-michaelides.jpg';
import img3 from './images/book-of-lost-names-by-kristin-harmel.jpg';

export const books = [
  {
    author: 'Fredrick Backman',
    title: 'A Man Called Ove',
    // img: './images/a-man-called-ove-by-fredrick-backman.jpg',
    img: img1,
    id: 1,
  },
  {
    author: 'Alex Michaelides',
    title: 'The Silent Patient',
    // img: './images/the-silent-patient-by-alex-michaelides.jpg',
    img: img2,
    id: 2,
  },
  {
    author: 'Kristin Harmel',
    title: 'Book of Lost Names',
    // img: './images/book-of-lost-names-by-kristin-harmel.jpg',
    img: img3,
    id: 3,
  },
];
